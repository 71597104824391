var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('ChangeRequestsStatusModal',{attrs:{"is-visible":_vm.isChangeStatusModalVisible,"selected-requests-ids":_vm.selectedRowsIds},on:{"onCancel":function($event){_vm.isChangeStatusModalVisible = false},"onOk":_vm.onBulkStatusChangeOk,"onError":_vm.onBulkStatusChangeError}}),_c('div',{staticClass:"card-body"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","disabled":!_vm.selectedRowsIds.length},on:{"click":function($event){_vm.isChangeStatusModalVisible = !_vm.isChangeStatusModalVisible}}},[_vm._v(" Изменить статус ")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.onSelectedRowsChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'traineeType')?_c('span',[_vm._v(" "+_vm._s(_vm.returnType(props.row))+" ")]):(props.column.field === 'traineeName')?_c('span',[_vm._v(" "+_vm._s(_vm.returnName(props.row))+" ")]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},_vm._l(([
                  'Новая заявка',
                  'Успешная запись',
                  'В работе',
                  'Отказ' ]),function(status){return _c('b-dropdown-item',{key:props.row.id + status,attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.changeStatus(props.row.id, status)}}},[_c('span',[_vm._v(_vm._s(status))])])}),1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('infinite-loading',{attrs:{"spinner":"bubbles"},on:{"infinite":_vm.infiniteScroll}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1),_c('div',[_c('div',{staticClass:"demo-inline-spacing"}),_c('b-modal',{attrs:{"id":"modal-primary","ok-only":"","ok-title":"Отмена","modal-class":"modal-primary","centered":"","title":"Primary Modal"},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('div',[_vm._v("Вы действительно хотите удалить абонемент?")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.deleteItem(_vm.clubToDelete)}}},[_vm._v(" Удалить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }