<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import crud_module from '@/libs/crud_module'

const requests_module = crud_module({
  entity_name: 'workout-request',
})

export default {
  name: 'ChangeRequestsStatusModal',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    selectedRequestsIds: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStatus: null,
      workoutRequestStatusList: {
        NEW: 'Новая заявка',
        PROCESSING: 'В работе',
        SUCCESS: 'Успешная запись',
        REJECT: 'Отказ',
      },
    }
  },
  computed: {
    isModalVisible: {
      get() {
        return this.isVisible
      },
      set() {
        return this.isVisible
      },
    },
  },
  methods: {
    async save() {
      const response = await requests_module.arbitraryRequest.patch('bulk', {
        requests: this.selectedRequestsIds,
        status: this.selectedStatus,
      }).catch(err => this.$emit('onError', err))

      if (response.data) {
        this.$emit('onOk', this.selectedRequestsIds)
      } else {
        this.$emit('onError')
      }
    },
    cancel() {
      this.$emit('onCancel')
    },
  },
}
</script>

<template>
  <div>
    <b-modal
      v-model="isModalVisible"
      title="Изменить статус выбранным заявкам"
      ok-title="Подтвердить"
      cancel-title="Отменить"
      centered
      @ok="save"
      @hidden="cancel"
      @cancel="cancel"
    >
      <b-dropdown
        :text="selectedStatus || 'Статус не выбран'"
        variant="primary"
        class="w-100 mt-1"
        menu-class="w-100"
      >
        <b-dropdown-item
          v-for="value in workoutRequestStatusList"
          :key="value"
          class="w-100"
          @click="selectedStatus = value"
        >
          {{ value }}
        </b-dropdown-item>
      </b-dropdown>
    </b-modal>
  </div>
</template>

<style scoped lang="scss"></style>
