<template>
  <div class="card">
    <ChangeRequestsStatusModal
      :is-visible="isChangeStatusModalVisible"
      :selected-requests-ids="selectedRowsIds"
      @onCancel="isChangeStatusModalVisible = false"
      @onOk="onBulkStatusChangeOk"
      @onError="onBulkStatusChangeError"
    />

    <div class="card-body">
      <b-button
        class="mb-2"
        variant="primary"
        :disabled="!selectedRowsIds.length"
        @click="isChangeStatusModalVisible = !isChangeStatusModalVisible"
      >
        Изменить статус
      </b-button>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="onSelectedRowsChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'traineeType'">
            {{ returnType(props.row) }}
          </span>

          <span v-else-if="props.column.field === 'traineeName'">
            {{ returnName(props.row) }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-for="status in [
                    'Новая заявка',
                    'Успешная запись',
                    'В работе',
                    'Отказ',
                  ]"
                  :key="props.row.id + status"
                  variant="outline-primary"
                  @click="changeStatus(props.row.id, status)"
                >
                  <span>{{ status }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>
    <div>
      <!-- button trigger -->
      <div class="demo-inline-spacing" />

      <!-- modal -->
      <b-modal
        id="modal-primary"
        v-model="modalIsOpen"
        ok-only
        ok-title="Отмена"
        modal-class="modal-primary"
        centered
        title="Primary Modal"
      >
        <div>Вы действительно хотите удалить абонемент?</div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1"
          variant="primary"
          @click="deleteItem(clubToDelete)"
        >
          Удалить
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BBadge, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crud_module from '../../../libs/crud_module'
import ChangeRequestsStatusModal from '@/views/clubs/requests/ChangeRequestsStatusModal.vue'

const requests_module = crud_module({
  entity_name: 'workout-request',
})

export default {
  components: {
    ChangeRequestsStatusModal,
    VueGoodTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isChangeStatusModalVisible: false,
      skipCount: 9,
      maxResultCount: 9,
      modalIsOpen: false,
      clubToDelete: null,
      selectedRowsIds: [],
      columns: [
        // {
        //   label: 'ID',
        //   field: 'id',
        // },
        {
          label: 'Название',
          field: 'traineeName',
        },
        {
          label: 'Тип',
          field: 'traineeType',
        },
        {
          label: 'Дата',
          field: this.formatDate,
        },
        {
          label: 'Имя',
          field: 'name',
        },
        {
          label: 'Фамилия',
          field: 'surname',
        },
        {
          label: 'Телефон',
          field: 'phone',
        },
        {
          label: 'Кол-во',
          field: 'count',
        },
        {
          label: 'Статус',
          field: 'status',
        },
        {
          label: 'Смена статуса',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Новая заявка': 'light-primary',
        'Успешная запись': 'light-success',
        Отказ: 'light-danger',
        'В работе': 'light-warning',
        // 'Новая заявка': 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  async created() {
    try {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      this.rows = await requests_module
        .fetchItems(payload)
        .then(res => res.data.rows)
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async onBulkStatusChangeOk(ids) {
      await Promise.all(ids.map(id => this.updateRequestRecord(id)))

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          text: 'Статусы обновлены успешно',
          variant: 'success',
        },
      })
    },
    onBulkStatusChangeError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ошибка',
          text: err || 'Неизвестная ошибка',
          variant: 'error',
          icon: 'BellIcon',
        },
      })
    },
    onSelectedRowsChange({ selectedRows }) {
      this.selectedRowsIds = selectedRows.map(item => item.id)
    },
    async changeStatus(id, status) {
      await requests_module.arbitraryRequest.patch('bulk', {
        requests: [id],
        status,
      })
      await this.updateRequestRecord(id)
    },
    async updateRequestRecord(id) {
      const item = await requests_module.fetchItem(id).then(res => res.data)
      const index = this.rows.findIndex(row => row.id === id)
      if (index !== -1) {
        this.$set(this.rows, index, item)
      }
    },
    returnType(row) {
      const {
        workout, subscription, trainer, schedule,
      } = row

      if (workout) {
        return 'Тренировка'
      }

      if (subscription) {
        return 'Абонимент'
      }

      if (trainer) {
        return 'Тренер'
      }

      if (schedule) {
        return 'Пробная тренировка'
      }

      return 'Неизвестно'
    },
    returnName(row) {
      const {
        workout, subscription, trainer, schedule,
      } = row
      return (
        workout?.type
        || subscription?.name
        || schedule?.description.replaceAll(/<[^>]*>/g, '')
        || `Тренер ${trainer?.name} ${trainer?.surname}`
      )
    },
    async infiniteScroll($state) {
      const payload = {
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await requests_module
        .fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteItem(id) {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      await requests_module.deleteItem(id)
      await requests_module.fetchItems(payload).then(response => {
        this.rows = response.data.rows
      })
      this.modalIsOpen = false
    },
    formatDate(rowObj) {
      const newDate = new Date(rowObj.createdAt)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      return newDate.toLocaleDateString('ru', options)
    },
  },
}
</script>

<style lang="scss">
.edit-button {
  padding: 0.65rem 1.28rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(115, 103, 240, 0.12);
  }
}

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
